const { globalHistory } = require('@reach/router')

const OFFSET = 440

/**
 * Scroll to the posts when navigate between pages
 * in blog. It is necessary to show to the users
 * that there are more blog posts.
 */
exports.shouldUpdateScroll = ({
  pathname,
  routerProps: {
    location: { hash },
  },
}) => {
  if (/\/blog(\/.+?)?\/page\/\d+$/gm.test(pathname)) {
    window.scrollTo(0, 150)
    return false
  }

  if (hash && /glossary/.test(pathname)) {
    window.setTimeout(() => {
      const el = document.querySelector(hash)
      if (el) return window.scrollTo(0, el.offsetTop + OFFSET)
      return false
    })

    return false
  }

  return true
}

exports.onRouteUpdate = ({ location: { hash, pathname } }) => {
  if (/glossary/.test(pathname) && hash) {
    window.setTimeout(() => {
      const el = document.querySelector(hash)
      if (el) return window.scrollTo(0, el.offsetTop + OFFSET)
      return false
    })
  }
}

/**
 * This is a workaround for a bug in Gatsby
 *
 * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
 */
exports.onInitialClientRender = () => {
  globalHistory._onTransitionComplete()
}
